<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin" style="background-color: rgba(0,0,0,0.5) !important;padding:25px">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1 text-muted">Sign In</h3>
        <p class="text-muted font-weight-semi-bold">
          Enter your username and password
        </p>
      </div>

      <!-- begin::Form-->
      <b-form class="form" style="min-width: 400px" @submit.stop.prevent="onSubmit">
        

        <b-form-group id="example-input-group-1" label="" label-for="example-input-1">
          <b-form-input placeholder="Email" class="form-control form-control-solid h-auto py-5 px-6" id="example-input-1"
            name="example-input-1" v-model="$v.form.email.$model" :state="validateState('email')"
            aria-describedby="input-1-live-feedback"></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="example-input-group-2" label="" label-for="example-input-2">
          <b-form-input placeholder="Password" class="form-control form-control-solid h-auto py-5 px-6" type="password"
            id="example-input-2" name="example-input-2" v-model="$v.form.password.$model"
            :state="validateState('password')" aria-describedby="input-2-live-feedback"></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Password is required.
          </b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap justify-content-center align-items-center">
          <button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 w-100 font-size-3">
            Sign In
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form -->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => this.$router.push("/exchanges"));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
